<template>
  <div>
    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area ptb--60 bg_image bg_image--27"
      data-black-overlay="7"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center blog-single-page-title pt--100">
              <h1 class="heading-title theme-gradient">
                Désabonnement
              </h1>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Start Blog Details Area  -->
    <div class="rn-blog-details pt--60 pb--60 bg_color--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class=" align-content-center align-center text-center">
              <div class="text-center " style="min-height:300px">
                <v-col class="py-5">
                  Souhaitez-vous ne plus faire partie du réseau d'alerte mes
                  amis de confiance :
                </v-col>
                <v-col class="py-5">
                  <v-btn
                    class=""
                    color="primary"
                    :loading="isDeleting"
                    :disabled="isDeleting"
                    v-on:click="disableContact()"
                  >
                    Oui
                    <template v-slot:isDeleting>
                      <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span>
                    </template>
                  </v-btn>
                </v-col>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Blog Details Area  -->
  </div>
</template>

<script>
import API from "@aws-amplify/api";
import Swal from "sweetalert2";
import { load } from "recaptcha-v3";

export default {
  components: {},
  async mounted() {
    await this.recaptcha();
  },
  methods: {
    async disableContact() {
      if (this.$route.query.id === undefined) {
        Swal.fire({
          title: "",
          text: "Une erreur est survenue, merci de réessayer ultérieurement",
          icon: "error",
          heightAuto: false
        });
        return;
      }
      this.isDeleting = true;

      this.token = await this.captcha.execute("track");
      if (this.token === undefined) {
        Swal.fire({
          title: "",
          text: "Une erreur est survenue, merci de réessayer ultérieurement",
          icon: "error",
          heightAuto: false
        });
        this.isDeleting = false;
        return;
      }

      const result = await API.post("apicc7ddc29friend", "/unsubscribe", {
        body: {
          id: this.$route.query.id,
          "g-recaptcha-response": this.token
        }
      });
      if (result.status === 1) {
        Swal.fire({
          title: "",
          text: "Votre désabonnement a bien été pris en compte",
          icon: "info",
          heightAuto: false
        });
        this.$router.push("/");
      } else {
        Swal.fire({
          title: "",
          text: "Une erreur est survenue, merci de réessayer ultérieurement",
          icon: "error",
          heightAuto: false
        });
      }
      this.isDeleting = false;
    },
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      this.captcha = await load("6LdnVjsdAAAAAOOL4CEwlEDp8O2tbucM0-hrg2a2");
    }
  },
  data() {
    return {
      isDeleting: false
    };
  }
};
</script>
